import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Auth } from 'aws-amplify';
import 'ag-grid-enterprise';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-balham.css';

import StyledLayout from './styles';
import cavendishTheme from './assets/theme';
import Header from './components/Header';
import Footer from './components/Footer';
import OrderManagement from './pages/OrderManagement';
import PageForbidden from './components/PageForbidden';
import PageNotFound from './components/PageNotFound';
import ProductCatalogue from './pages/InventoryManagement/ProductCatalogue';
import SkuGroups from './pages/InventoryManagement/SkuGroups';
import Toast from './components/Toast';
import amplifyConfig from './config/amplifyConfig';
import { IAuthState, IUserPermissions } from './interfaces/IAuthState';
import mountHubListener, {
  getUser,
  getUserPermissions
} from './services/authListener';
import Reporting from './pages/Reporting/OrderReporting';
import ScheduledReports from './pages/Reporting/OrderReporting/components/ScheduledReports';
import StockAlignmentReporting from './pages/Reporting/StockAlignmentReporting';
import HierarchyDeliveryMapping from './pages/Configuration/HierarchyDeliveryMapping';
import OriginSegmentMapping from './pages/Configuration/OriginSegmentMapping';
import ReturnBagOverrides from './pages/Configuration/ReturnBagOverrides';
import ProductPresetAddOns from './pages/Configuration/ProductPresetAddOns';
import StateChangeAddons from './pages/Configuration/StateChangeAddons';
import LowValueDeliveryOverride from './pages/Configuration/LowValueDeliveryOverride';

import Home from './pages/Home';
import HierarchyAddOns from './pages/Configuration/HierarchyAddOns';
import ReturnsReporting from './pages/Reporting/ReturnsReporting';
import InventoryReporting from './pages/Reporting/InventoryReporting';
import DeviceEnrolmentReporting from './pages/Reporting/DeviceEnrolmentReporting';
import AuthVerify from './components/AuthVerify/AuthVerify';
import Forecasting from './pages/Forecasting/Forecasting';
import StoreConfig from './pages/Forecasting/StoreConfig';

Auth.configure(amplifyConfig);

const App: React.FC = () => {
  const [userState, setUserState] = useState<IAuthState>({
    authState: 'loading'
  });
  const [isToastVisible, setIsToastVisible] = useState(true);

  async function onLoad() {
    try {
      const user = await getUser();
      setUserState({
        authState: 'signedIn',
        user,
        permissions: getUserPermissions(user?.userData.attributes.profile)
      });
    } catch (e) {
      setUserState({ authState: 'signedOut' });
      console.log(e);
    }
  }

  useEffect(() => {
    mountHubListener(setUserState);
    onLoad();
  }, []);

  const InventoryManagementRoutes: React.FC<{
    permissions: IUserPermissions | undefined;
  }> = ({ permissions }) => (
    <Routes>
      <Route
        path='/product-catalogue'
        element={<ProductCatalogue permissions={permissions} />}
      />
      {permissions?.viewSkuGroups === true && (
        <Route
          path='/sku-groups'
          element={<SkuGroups permissions={permissions} />}
        />
      )}
      <Route path='*' element={<PageNotFound />} />
    </Routes>
  );

  const ReportingRoutes: React.FC<{
    permissions: IUserPermissions | undefined;
  }> = ({ permissions }) => (
    <Routes>
      <Route path='/order' element={<Reporting />} />
      <Route path='/returns' element={<ReturnsReporting />} />
      <Route path='/scheduled-reports' element={<ScheduledReports />} />
      <Route path='/stock-alignment' element={<StockAlignmentReporting />} />
      <Route path='/inventory' element={<InventoryReporting />} />
      {permissions?.viewDeviceEnrolmentReport === true && (
        <Route
          path='/device-enrolment'
          element={<DeviceEnrolmentReporting />}
        />
      )}

      <Route path='*' element={<PageNotFound />} />
    </Routes>
  );

  const ConfigurationRoutes: React.FC<{ permissions: any }> = () => (
    <Routes>
      <Route
        path='/hierarchy-delivery'
        element={<HierarchyDeliveryMapping />}
      />
      <Route path='/origin-segment' element={<OriginSegmentMapping />} />
      <Route path='/return-bag-overrides' element={<ReturnBagOverrides />} />
      <Route path='/product-present-addons' element={<ProductPresetAddOns />} />
      <Route path='/state-change-addons' element={<StateChangeAddons />} />
      <Route
        path='/low-value-delivery-override'
        element={<LowValueDeliveryOverride />}
      />
      <Route path='/hierarchy-addons' element={<HierarchyAddOns />} />
      <Route path='*' element={<PageNotFound />} />
    </Routes>
  );

  const ForecastingRoutes: React.FC<{ permissions: any }> = () => (
    <Routes>
      <Route path='/retail-replenishment' element={<Forecasting />} />
      <Route path='/store-configuration' element={<StoreConfig />} />
      <Route path='*' element={<PageNotFound />} />
    </Routes>
  );

  return (
    <ThemeProvider theme={cavendishTheme}>
      {isToastVisible && <Toast />}
      <StyledLayout>
        <AuthVerify
          userState={userState}
          setUserState={setUserState}
          setIsToastVisible={setIsToastVisible}
        />
        <Header userState={userState} setUserState={setUserState} />
        <div className='page-content'>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/page-forbidden' element={<PageForbidden />} />
            {userState.authState === 'signedIn' && (
              <>
                <Route
                  path='/order-management/*'
                  element={
                    <OrderManagement permissions={userState?.permissions} />
                  }
                />
                <Route
                  path='/inventory-management/*'
                  element={
                    <InventoryManagementRoutes
                      permissions={userState?.permissions}
                    />
                  }
                />
                {userState.permissions?.viewOrderReports === true && (
                  <Route
                    path='/reporting/*'
                    element={
                      <ReportingRoutes permissions={userState?.permissions} />
                    }
                  />
                )}
                {userState.permissions?.viewConfigInfo === true && (
                  <Route
                    path='/configuration/*'
                    element={
                      <ConfigurationRoutes
                        permissions={userState?.permissions}
                      />
                    }
                  />
                )}
                {userState.permissions?.viewForecastingData === true && (
                  <Route
                    path='/forecasting/*'
                    element={
                      <ForecastingRoutes permissions={userState?.permissions} />
                    }
                  />
                )}
              </>
            )}
            <Route path='*' element={<PageNotFound />} />
          </Routes>
        </div>
        <Footer />
      </StyledLayout>
    </ThemeProvider>
  );
};

export default App;
