import React, { useState, SyntheticEvent, ChangeEvent } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { MenuItem, Select } from '@mui/material';
import { GridApi } from 'ag-grid-community';

import ControlledInput from '../../../../../components/ControlledInput';
import environment from '../../../../../environment';
import PlusIcon from '../../../../../assets/icons/plus.svg';
import GenericModal from '../../../../../components/GenericModal';
import Label from '../../../../../components/Label';
import StyledFormControl from './styles';

const supportedLanguages = [
  { value: 'Arabic', code: 'ar-sa' }, // Defaulting to Saudi Arabia for generic Arabic
  { value: 'Chinese (Hong Kong)', code: 'zh-hk' },
  { value: 'Chinese (Simplified)', code: 'zh-cn' }, // PRC for Simplified Chinese
  { value: 'Chinese (Traditional)', code: 'zh-tw' }, // Taiwan for Traditional Chinese
  { value: 'Czech', code: 'cs' },
  { value: 'Danish', code: 'da' },
  { value: 'Dutch', code: 'nl' }, // Standard Dutch
  { value: 'English (UK)', code: 'en-gb' },
  { value: 'English (US)', code: 'en-us' },
  { value: 'Filipino', code: 'en' }, // Filipino is commonly English but can also be 'tl' for Tagalog
  { value: 'Finnish', code: 'fi' },
  { value: 'French', code: 'fr' }, // Standard French
  { value: 'German', code: 'de' }, // Standard German
  { value: 'Hebrew', code: 'he' },
  { value: 'Hindi', code: 'hi' },
  { value: 'Hungarian', code: 'hu' },
  { value: 'Indonesian', code: 'id' },
  { value: 'Italian', code: 'it' }, // Standard Italian
  { value: 'Japanese', code: 'ja' },
  { value: 'Korean', code: 'ko' }, // Standard Korean
  { value: 'Norwegian (Bokmal)', code: 'nb' },
  { value: 'Polish', code: 'pl' },
  { value: 'Portuguese (Brazil)', code: 'pt-br' },
  { value: 'Portuguese (Portugal)', code: 'pt' },
  { value: 'Russian', code: 'ru' },
  { value: 'Spanish', code: 'es' }, // Spain's Spanish
  { value: 'Spanish (Latin America)', code: 'es-mx' }, // Defaulting to Mexico for Latin American Spanish
  { value: 'Swedish', code: 'sv' }, // Standard Swedish
  { value: 'Thai', code: 'th' },
  { value: 'Turkish', code: 'tr' },
  { value: 'Ukrainian', code: 'uk' },
  { value: 'Vietnamese', code: 'vi' }
];
interface ICreateCustomerModalProps {
  gridApi: GridApi | undefined;
  getRowDataStores: (gridApiParam: GridApi) => void;
}

const CreateCustomerModal: React.FC<ICreateCustomerModalProps> = ({
  gridApi,
  getRowDataStores
}) => {
  const [enrollmentScheme, setEnrollmentScheme] = useState<string>('');
  const [customerName, setCustomerName] = useState<string>('');
  const [ownerEmail, setOwnerEmail] = useState<string>('');
  const [ownerEmailError, setOwnerEmailError] = useState<string>('');
  const [adminEmails, setAdminEmails] = useState<string[]>([]);
  const [preferredLanguage, setPreferredLanguage] =
    useState<string>('English (UK)');

  const enrollmentSchemes = [{ value: 'Android Zero Touch', code: 'azt' }];

  const clearForm = () => {
    setEnrollmentScheme('');
    setCustomerName('');
    setOwnerEmail('');
    setAdminEmails([]);
    setPreferredLanguage('English (UK)');
  };

  const handleSubmit =
    (
      setLoading: (loading: boolean) => void,
      setOpen: (open: boolean) => void
    ) =>
    async (event: SyntheticEvent) => {
      event.preventDefault();
      setLoading(true);
      try {
        await axios.post(
          `${environment.apiPathDeviceEnrolment}aztCreateCustomer`,
          {
            customerName: customerName,
            ownerEmails: ownerEmail,
            adminEmails: adminEmails,
            preferredLanguage: preferredLanguage
          },
          {
            ...environment.paramsDeviceEnrolment
          }
        );
        getRowDataStores(gridApi as GridApi);
        toast.success('Customer successfully created');
      } catch (err: any) {
        if (err.response) {
          toast.error(err.response.data.error);
        } else {
          toast.error(err.message);
        }
      } finally {
        setOpen(false);
        setLoading(false);
        clearForm();
      }
    };

  const validateEmail = (email: string) => {
    const validateEmailRegEx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return validateEmailRegEx.test(email);
  };
  const handleOwnerEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOwnerEmail(e.target.value);

    if (!validateEmail(e.target.value)) {
      setOwnerEmailError('Invalid email address');
    } else {
      setOwnerEmailError('');
    }
  };

  return (
    <GenericModal
      btnTitle='Create Customer'
      classes='btn--w-200-px btn--blue'
      endIcon={<img src={PlusIcon} alt='Add' />}
      clearForm={clearForm}
      formTitle='Create Customer'
      formError={!customerName || !ownerEmail || !!ownerEmailError}
      handleSubmit={handleSubmit}
      warnings={[
        'By pressing submit, you confirm that you will create a new customer'
      ]}
    >
      <Label
        id='enrolment-scheme'
        control={
          <StyledFormControl variant={'outlined' as any}>
            <Select
              data-testid='select-testid'
              displayEmpty
              value={enrollmentScheme}
              onChange={(event: any) => setEnrollmentScheme(event.target.value)}
            >
              <MenuItem key='placeholder' value='' disabled>
                Select enrolment scheme
              </MenuItem>
              {enrollmentSchemes.map((option) => (
                <MenuItem key={option.code} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
        }
        label='Enrolment Scheme'
        classes='label--w-100'
        required
      />
      {enrollmentScheme && (
        <>
          <ControlledInput
            id='customer-name'
            placeholder='Type customer name here'
            value={customerName}
            handleChange={(e) => {
              setCustomerName(e.target.value);
            }}
            label='Name'
            type='text'
            classes='label--w-100'
            required
          />
          <ControlledInput
            id='owner-email'
            placeholder='Type owner email here'
            value={ownerEmail}
            handleChange={handleOwnerEmailChange}
            label='Owner email'
            type='email'
            classes='label--w-100'
            required
          />
          <ControlledInput
            id='admin-emails'
            placeholder='Type admin emails here'
            value={adminEmails.join(',')}
            handleChange={(e) => setAdminEmails(e.target.value.split(','))}
            label='Admin emails'
            type='text'
            classes='label--w-100'
          />
          <Label
            id='preferred-language'
            control={
              <StyledFormControl variant={'outlined' as any}>
                <Select
                  data-testid='select-testid'
                  displayEmpty
                  value={preferredLanguage}
                  onChange={(event: any) =>
                    setPreferredLanguage(event.target.value)
                  }
                >
                  <MenuItem key='placeholder' value='' disabled>
                    Select preferred language
                  </MenuItem>
                  {supportedLanguages.map((option) => (
                    <MenuItem key={option.code} value={option.value}>
                      {option.value}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>
            }
            label='Preferred language'
            classes='label--w-100'
            required
          />
        </>
      )}
    </GenericModal>
  );
};

export default CreateCustomerModal;
